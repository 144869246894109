import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Parallax } from 'react-parallax';

import { Container, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../components/Layout';
import Carousel from '../components/Carousel';
import CardWide from '../components/Cards/CardWide';
import CardHorizontal from '../components/Cards/CardHorizontal';
import SEO from '../components/SEO';

const useStyles = makeStyles(theme => ({
  root: {
    background: 'transparent',
    boxShadow: 'none',
    padding: theme.spacing(3, 2),
    '& ul': {
      display: 'flex',
      flexDirection: 'column',
      listStyleType: 'decimal',
      '& li': {
        alignItems: 'center',
        '& p': {
          float: 'left',
          paddingLeft: '8px',
        },
      },
    },
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#fa0000',
    border: '1px solid transparent',
    '& a': {
      color: 'white',
      textDecoration: 'none',
      textShadow: '1px 1px 5px #000',
    },
    '&:hover': {
      backgroundColor: 'white',
      border: '1px solid #fa0000',
      '& a': {
        color: '#fa0000',
        textShadow: 'none',
      },
    },
  },
}));

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop - 110);

export const query = graphql`
  query {
    mobileImage1: file(relativePath: { eq: "palsie/pals1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage1: file(relativePath: { eq: "palsie/pals1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mobileImage2: file(relativePath: { eq: "palsie/pals2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage2: file(relativePath: { eq: "palsie/pals2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mobileImage3: file(relativePath: { eq: "palsie/pals3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage3: file(relativePath: { eq: "palsie/pals3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const Home = ({ data, path, location }) => {
  const classes = useStyles();
  const esi = useRef(null);
  const executeScroll = () => scrollToRef(esi);

  const sources1 = [
    data.mobileImage1.childImageSharp.fluid,
    {
      ...data.desktopImage1.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];
  const sources2 = [
    data.mobileImage2.childImageSharp.fluid,
    {
      ...data.desktopImage2.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];
  const sources3 = [
    data.mobileImage3.childImageSharp.fluid,
    {
      ...data.desktopImage3.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const images = [sources2, sources3, sources1];

  const seo = {
    title: 'M.Pals Stukdoors - Purmerend, Hoorn en Amsterdam',
    description: `Op zoek naar een stukadoor in de omgeving van Purmerend, Waterland, Amsterdam, Zaanstad, en Hoorn? M.Pals levert niets anders dat top kwaliteit.`,
  };

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        pathname={path}
        location={location}
      />
      <div className='carousel'>
        <Carousel images={images} />
        <div className='carousel__text'>
          <h1>
            M.PALS <br />
            Stukadoors
          </h1>

          <div className='hidden-sm hidden-md hidden-lg'>
            <a href='tel:0629575549'>
              <Button
                role='button'
                variant='contained'
                className='btn heading__button'
              >
                <i className='material-icons mr-1x'>phone</i>Krijg advies op
                maat
              </Button>
            </a>
          </div>
          <AniLink fade to='/contact' className='hidden-xs'>
            <Button
              role='button'
              variant='contained'
              className='btn heading__button btn--small'
            >
              <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
            </Button>
          </AniLink>
        </div>
        <button
          type='button'
          href='#'
          onClick={executeScroll}
          className='carousel__arrows'
        >
          <i className='material-icons'>keyboard_arrow_down</i>
          <i className='material-icons'>keyboard_arrow_down</i>
        </button>
      </div>
      <Container fixed className='mt-10x mb-10x'>
        <CardWide
          className='center-xs'
          headerElem='h2'
          headerTitle='M.Pals Stukadoors'
          ref={esi}
        >
          <>
            <p>
              M.Pals is actief als <strong>stukadoor</strong> in de omgeving van
              Purmerend, Waterland, Amsterdam, Zaanstad, en Hoorn. Met onze
              ruime ervaring is M.Pals Stukadoors het juiste adres voor
              klantgerichte service, uitstekende adviezen en zeer vakkundig
              stukwerk.
            </p>
            <p>
              Onze medewerkers zetten zich iedere dag voor de volle 100% in voor
              een tevreden klant. M.Pals stukadoors biedt een divers assortiment
              aan en zorgt ervoor dat alles precies naar wens is.
            </p>
          </>
        </CardWide>
        <CardHorizontal
          img={sources3}
          imgClass={classNames(classes.img, 'img--pos img')}
          imgAlt='M.Pals Stukadoors'
          imgTitle='M.Pals Stukadoors'
          containerClass='mt-10x cards'
        >
          <div className='mt-md-0 pl-md-4x'>
            <h3>Specialisme van M.Pals Stukadoors</h3>
            <p>
              M.Pals Stukadoors is gespecialiseerd in diverse diensten waarbij u
              gegarandeerd top resultaat krijgt. De volgende diensten bieden wij
              aan onder andere aan:
            </p>
            <ul>
              <li>Glad pleisterwerk</li>
              <li>Sierpleister</li>
              <li>Behang klaar </li>
              <li>Schuurplafond </li>
              <li>Plafond plint </li>
            </ul>
          </div>
        </CardHorizontal>
      </Container>
      <Parallax
        bgImage={sources2[0].src}
        bgImageAlt='M.Pals Stukadoors'
        strength={400}
        bgImageStyle={{ filter: 'brightness(0.5)!important' }}
        bgClassName='parallax__image'
      >
        <div className='parallax'>
          <div className='parallax__element'>
            <i className='material-icons'>format_paint</i>
            <h3>Vakmannen</h3>
          </div>
          <div className='parallax__element'>
            <i className='material-icons'>school</i>
            <h3>Hoge kwaliteit</h3>
          </div>
          <div className='parallax__element'>
            <i className='material-icons'>emoji_objects</i>
            <h3>Slimme oplossingen</h3>
          </div>
        </div>
        <div style={{ height: '400px' }} />
      </Parallax>
      <div className='pt-10x pb-10x bg--white'>
        <Container fixed>
          <CardHorizontal
            img={sources2}
            imgClass='img--pos img'
            imgAlt='M.Pals Stukadoors'
            imgTitle='M.Pals Stukadoors'
            containerClass='cards'
            textFirst
          >
            <div className='mt-md-0 pl-md-4x'>
              <h3 className='text--brand'>Kwaliteit</h3>
              <p className='text--brand'>
                Bij M.Pals staat kwaliteit centraal. Met onze ruime ervaring en
                kennis mogen wij gerust zeggen dat M.Pals alleen maar kwaliteit
                levert. Of het nu gaat om schuurplafond of sierpleister, wij
                realiseren dit voor u.
              </p>
            </div>
          </CardHorizontal>
        </Container>
      </div>
    </Layout>
  );
};

Home.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Home;
